<template>
  <brand-header :image="urlImage" :altImage="altImagen" />
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <router-link class="header-content__container-logo" to="/">
          <img class="header-content__img" src="https://images.betimages.com/Betslip/images/easymoney/logo.png"
            alt="gameday.bet" />
        </router-link>
      </div>
      <div class="header-content__cta">

        <!-- <span class="header-content__language material-symbols-rounded">language</span> -->
        <div class="header-buttons">
          <div class="container-bet-lag">
            <a href="#" class="btn-bet secondary-button secondary-button__betslip" @click="openModal(false)">Login</a>
            <a class="btn-login secondary-button secondary-button__agent" @click="openModal(true)">{{ $t('Classic') }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="header-content__info">
      <ul class="header-content__info--list">
        <li data-aos="zoom-in" data-aos-offset="-150" :data-aos-delay="100 * index" :data-aos-duration="800"
          data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false"
          data-aos-anchor-placement="top-center" class="header-content__info--list-item" @click="isActive = !isActive"
          v-for="(route, index) in routeshead" :key="index">
          <router-link :to="route.route">
            <i :class="route.icon" aria-hidden="true"></i>
            {{ route.item }}
          </router-link>
        </li>
      </ul>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
import ModalComponent from '@/components/modal.vue'
import BrandHeader from '@/components/brand-header.vue';
// import MenuComponent from '@/components/menu.vue'
import { useI18n } from 'vue-i18n';
import { reactive, watch } from 'vue';
import { computed } from 'vue'

export default {
  name: "HeaderComponent",
  components: {
    ModalComponent,
    BrandHeader
    // MenuComponent
  },
  watch: {
    $route(newVar) {
      let pathName = computed(() => newVar);
      this.detectLang(pathName.value.name);
    }
  },
  methods: {

    openModal(type) {
      this.$refs.modal.openModal(type);
    },
    changeLanguage(locale) {
      if (this.$i18n.locale != locale) {
        let tempLocaleCurrent = this.$i18n.locale;
        this.$i18n.locale = locale;

        const t = this.$t;
        const currentRoute = this.$router.currentRoute.value;
        let templang = currentRoute.name.replace('-lang' + tempLocaleCurrent, "");

        if (currentRoute.path !== '/' && currentRoute.path !== '/apk') {
          let langChange = `routes.${locale}.${templang}`;
          const translatedPath = `/${t(langChange)}`;
          this.$router.push(translatedPath);
        }
      }


    },
    getTranslatedRoutes(t) {

      const routes = this.$router.options.routes.map((route) => ({
        ...route,
        path: route.path !== '/' ? `/${t(`routes.${route.name}`)}` : '/',
      }));

      return routes;
    },
    detectLang(pathName) {
      if (pathName.includes("langes")) {
        this.$i18n.locale = "es";
        this.changeLanguage("es")
      } else if (pathName.includes("langen")) {
        this.$i18n.locale = "en";
        this.changeLanguage("en")
      } else if (pathName.includes("langpt")) {
        this.$i18n.locale = "pt";
        this.changeLanguage("pt")
      } else {
        console.log("default")
      }
    }
  },
  setup() {
    const DGS_SITEID = 733;
    const backendUrl = "easymoney.ag";
    const { t, locale } = useI18n();

    const routeshead = reactive(
      [
        {
          icon: "fa fa-futbol-o",
          item: t("routeshead.0.name"),
          route: t('routeshead.0.route')
        },
        {
          icon: "fa fa-star",
          item: t('routeshead.1.name'),
          route: t('routeshead.1.route')
        },
        {
          icon: "fa fa-star",
          item: t('routeshead.2.name'),
          route: t('routeshead.2.route')
        },
        {
          icon: "fas fa-horse-head",
          item: t('routeshead.4.name'),
          route: t('routeshead.4.route')
        }
      ]
    )
    // Observar los cambios de idioma y actualiza los slides
    const updateSlides = () => {
      routeshead.forEach((routehead, index) => {
        routehead.item = t(`routeshead.${index}.name`);
        routehead.route = t(`routeshead.${index}.route`);
      });
    };

    // Escuchar los cambios de idioma y actualiza los slides
    watch(locale, () => {
      updateSlides();
    });

    return {
      DGS_SITEID,
      backendUrl,
      routeshead
    }
  },
};
</script>
