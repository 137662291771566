<template>

<transition name="modal-fade"> 
  <div class="modal-backdrop" v-if="showModal">
    <div class="modal-content__heading">
      
      <div v-if="!is_classic">
        <h3>{{ $t('UserLoginB') }}</h3>
      </div>
      <div v-if="is_classic">
        <h3>{{ $t('UserLoginC') }}</h3>
      </div>
      <button class="close-button" @click="closeModal">
        <span class="material-symbols-rounded">close</span>
      </button>
      
      
      <span class="line"></span>
    </div>
    
    <div class="modal-content">
      <!-- Contenido de tu modal aquí -->
      <template v-if="!is_classic">
        <form class="login__form" name="LoginForm" action="javascript:void(0)" onsubmit="BackEndLogin(this); return false">
          <div class="login__form--item">
            <label class="login__form--label" for="username">{{ $t('Username') }}</label>
            <input class="login__form--input" name="username" id="username" type="text" required />
          </div>
          <div class="login__form--item">
            <label class="login__form--label" for="password">{{ $t('Password') }}</label>
            <input class="login__form--input" type="password" name="password" id="password" required />
          </div>
          <div class="text-center mt-2">
            <span name="msj_loading" id="id-login-loading"></span>
            <small class="text-danger" name="msj_error_lg"></small>
          </div>
          <div class="login__btns-login">
            <input type="submit" class="login-button" name="send" id="send" :value="$t('LOGIN')">
            <input type="hidden" name="BackEndUrl" :value="`https://betslip.${BackEndUrl}`">
          </div>
          <div class="login__link">
            <a :href="`https://betslip.${BackEndUrl}/home/reset-pass`">Forgot password ?</a>
          </div>
        </form>
      </template>
      
      <template v-if="is_classic">
        <form class="form-login form-desktop" method="post" :action="`//wager.${BackEndUrl}/DefaultLogin.aspx`">
          <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
          <input type="hidden" name="errorURL" :value="`//www.${BackEndUrl}/?login-error`"/> 
          <div class="login__form--item">
            <label class="login__form--label" for="username">{{ $t('Username') }}</label>
            <input type="text" name="account" id="account">
          </div>
          <div class="login__form--item">
            <label class="login__form--label" for="password">{{ $t('Password') }}</label>
            <input type="password" name="password" id="password">
          </div>
          <button class="btn-login secondary-button secondary-button__betslip" type="submit">{{$t('LOGIN')}}</button>
        </form>
        <form class="form-login form-mobile" method="post" :action="`//mobile.${BackEndUrl}/DefaultLogin.aspx`">
          <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
          <input type="hidden" name="errorURL" :value="`//www.${BackEndUrl}/?login-error`"/> 
          <div class="login__form--item">
            <label class="login__form--label" for="username">{{ $t('Username') }}</label>
            <input type="text" name="account" id="account">
          </div>
          <div class="login__form--item">
            <label class="login__form--label" for="password">{{ $t('Password') }}</label>
            <input type="password" name="password" id="password">
          </div>
          <button class="btn-login secondary-button secondary-button__betslip" type="submit">{{$t('LOGIN')}}</button>
        </form>
      </template>
  

    </div>
  </div>
</transition>
</template>
<script>
import { Transition } from 'vue';

  export default {
    name: 'ModalComponent',
    data() {
        return {
            DGS_SITEID: 733,
            BackEndUrl: "easymoney.ag",
            showModal: false,
            is_classic: false
        };
    },
    methods: {
        openModal(type) {
            this.showModal = true;
            this.is_classic = type;
        },
        closeModal() {
            this.showModal = false;
            this.is_classic = false;
        }
    },
    components: { Transition }
}
</script>